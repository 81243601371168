import styled from 'styled-components'
import { Box } from '@material-ui/core'
import logo from '../../assets/minutia_logo.svg'

const LayoutContainer = styled(Box)`
    display: grid;
    grid-auto-rows: auto 1fr auto;
    min-height: 100vh;
    box-sizing: border-box;
`
const Logo = styled.img`
    padding-bottom: 40px;
    padding-top: 40px;
    max-width: 60%;
    justify-self: center;
`

const Layout = ({children, ...props}) => {
    return (
        <LayoutContainer {...props}>
            <Logo src={logo}/>
            { children }
        </LayoutContainer>
    )
}

export default Layout