import React, { useState } from 'react'
import { Backdrop, CircularProgress, Typography, Box } from '@material-ui/core'
import Layout from '../../components/Layout'
import MainButton from '../../components/MainButton'
import { useHistory } from "react-router-dom";

function WelcomeBox() {
    return (
        <Box>
            <Typography align="center" color="textPrimary" variant={'h4'}>Welcome to the Trivia challenge</Typography>
            <Typography align="center" color="textSecondary">You will be presented with 10 true or false questions. Can you get them all right?</Typography>
        </Box>
    )
}
function LoadingBackdrop({open}) {
    return (
        <Backdrop style={{zIndex: 100}} open={open}>
            <CircularProgress />
        </Backdrop>
    )
}

const HomeScreen = ({ begin }) => {

    const [ loading, setLoading ] = useState()
    const history = useHistory()

    const onBegin = async () => {
        setLoading(true)
        await begin()
        setLoading(false)
        history.push('/question/1')
    }

    return (
        <Layout py={4}>
            <WelcomeBox/>         
            <MainButton onClick={onBegin}>Begin</MainButton>
            { loading && <LoadingBackdrop open={true} /> }
        </Layout>
    )
}

export default HomeScreen
