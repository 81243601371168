import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { useParams, useHistory, useLocation, Route, Switch } from 'react-router-dom'

import TrueFalseButtons from '../../components/TrueFalseButtons'
import QuestionCard from '../../components/QuestionCard'
import Stepper from '../../components/Stepper'
// import AnimatedSwitch from '../../components/AnimatedSwitch'


const QuestionScreen = ({ questions }) => {

    const [ answers, setAnswers ] = useState([])
    const [ answer, setAnswer ] = useState()
    const { questionIndex } = useParams()
    const history = useHistory()
    const location = useLocation()
    const trueIndex = parseFloat(questionIndex) - 1
    const isLastStep = () => { return (parseFloat(questionIndex) < questions.length ? false : true) }
    const currentQuestion = questions[trueIndex]

    const nextQuestion = (ans) => {
        setAnswers([...answers, ans])
        setAnswer()
        if(isLastStep()) return history.push(`/results`, [...answers, ans])
        history.push(`/question/${parseFloat(questionIndex) + 1}`)
    }

    const selected = (ans) => {
        setAnswer(ans)
        setTimeout(() => nextQuestion(ans), 150)
    }

    return (
        <Layout py={4}>
            <Stepper stepNumber={location.pathname.split('/')[2]} />
            <Switch location={location}>
                <Route path="/question/:questionIndex">
                        <QuestionCard stepNumber={location.pathname.split('/')[2]} question={currentQuestion}/>
                </Route>    
            </Switch>
            <TrueFalseButtons selection={answer} newSelection={ans => selected(ans)} />
        </Layout>
    )
}

export default QuestionScreen
