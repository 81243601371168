import React from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

import sunglasses from '../../assets/sunglasses.svg'
import smiling from '../../assets/smiling.svg'
import meh from '../../assets/meh.svg'

const ScoreEmoji = styled.img`
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
`
function getScoreEmoji(score) {
    if (score < 6) return meh
    if (score < 8) return smiling
    return sunglasses
}


const ScoreCard = ({ score, count }) => {
    
    return (
        <Box alignItems="center" display="grid" py={3}>
            <ScoreEmoji src={getScoreEmoji(score)} />
            <Typography color={'textPrimary'} style={{textAlign: 'center'}} variant="h5">{`You scored ${score} / ${count}`}</Typography>
        </Box>
    )
}

export default ScoreCard
