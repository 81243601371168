import { createTheme } from "@material-ui/core";

const theme = createTheme(
    {
        palette: {
            primary: {
              main: '#0B1532',
            },
            secondary: {
              main: '#FCD34D',
            },
            background: {
                paper: '#0F1C43',
                default: '#0B1532'
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#9DA1AD'
            },
            divider: '#29314B'
        },
        typography: {
            fontFamily: 'Arimo'
        },
        shape: {
            borderRadius: '8px'
        },
        type: 'dark'
    }
)


export default theme