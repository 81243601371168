export const colors = {
    rainbow1: '#FCA5A5',
    rainbow2: '#FDBA74',
    rainbow3: '#FDE047',
    rainbow4: '#6EE7B7',
    rainbow5: '#7DD3FC',
    rainbow6: '#C4B5FD',
    rainbow7: '#F0ABFC',
}
export const colorsArray = [
    '#FCA5A5',
    '#FDBA74',
    '#FDE047',
    '#6EE7B7',
    '#7DD3FC',
    '#C4B5FD',
    '#F0ABFC', 
    '#FCA5A5',//Repeat
    '#FDBA74',
    '#FDE047',
]
export const colorsDarkArray = [
    '#531313', 
    '#502A01',
    '#463601',
    '#0B462F', 
    '#034563',
    '#3D1778',
    '#4C0458',
    '#531313',//Repeat
    '#502A01',
    '#463601', 
]

export const colorsDark = {
    rainbowDark1: '#531313',
    rainbowDark2: '#502A01',
    rainbowDark3: '#463601',
    rainbowDark4: '#0B462F',
    rainbowDark5: '#034563',
    rainbowDark6: '#3D1778',
    rainbowDark7: '#4C0458',
}