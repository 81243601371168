import React from 'react'
import { Typography, Box, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import he from 'he'
import styled from 'styled-components'
import { colorsArray, colorsDarkArray } from '../../colors'

const Container = styled(Box)`
`

const useStyles = makeStyles({
    root: props => ({
        backgroundColor: props.colorDark,
        color: props.color,
        marginBottom: '8px'
    })
})

const QuestionCard = ({ question, stepNumber }) => {
    // Set Chip colors
    const props = { color: colorsArray[stepNumber - 1], colorDark: colorsDarkArray[stepNumber - 1]}
    const classes = useStyles(props)

    const category = question.category.split(': ')[1] || question.category

    return (
        <Container>
            <Chip className={classes.root} size="small" label={category} />
            <Typography color="textPrimary" variant="h4">{he.decode(question.question)}</Typography>
        </Container>
    )
}

export default QuestionCard
