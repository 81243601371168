import React from 'react'
import { Button, Box } from '@material-ui/core'
import { ThemeProvider, createTheme, useTheme } from '@material-ui/core/styles';
import { Check, Close } from '@material-ui/icons';
import styled from 'styled-components';

const trueFalseTheme = createTheme({
    palette: {
        primary: {
            main: '#6EE7B7'
        },
        secondary: {
            main: '#FCA5A5'
        }
    }
})

const ButtonContainer = styled(Box)`
        display: flex;
        margin: 0;
        gap: ${props => props.theme.spacing(2)}px;
    `

const TrueFalseButtons = ({selection, newSelection}) => {
    const theme = useTheme()
    
    return (
        <ThemeProvider theme={trueFalseTheme}>
            <ButtonContainer theme={theme} my={2}>
                <Button startIcon={<Check/>} fullWidth onClick={() => newSelection("True")} variant={selection === 'True' ? 'containedPrimary' : 'outlinedPrimary'}>True</Button>
                <Button startIcon={<Close/>} fullWidth onClick={() => newSelection("False")} variant={selection === 'False' ? 'containedSecondary' : 'outlinedSecondary'}>False</Button>
            </ButtonContainer>
        </ThemeProvider>
    )
}

export default TrueFalseButtons
