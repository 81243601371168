import { Box, List, ListItem, ListItemText, ListItemIcon, Paper, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Layout from '../../components/Layout'
import MainButton from '../../components/MainButton'
import he from 'he'
import ScoreCard from '../../components/ScoreCard'

import notepad_check from '../../assets/notepad_check.svg'
import notepad_x from '../../assets/notepad_x.svg'

const ResultScreen = ({ questions }) => {

    const history = useHistory()
    const { state } = useLocation()
    const correctAnswers = questions.map(question => question.correct_answer)

    const playAgain = () => {
        history.push('/')
    }
    
    function isCorrect(correctAnswer, answer) {
        return correctAnswer === answer ? true : false
    }

    function score() {
        return state.filter((answer, index) => isCorrect(correctAnswers[index], answer)).length
    }

    return (
        <Layout py={4}>
            <Box>
                <Divider/>
                <ScoreCard score={score()} count={questions.length} />
                <Divider/>
                <List>
                {
                    questions.map((question, index) => {
                        return (
                            <Box py={1}>
                            <Paper>
                            <ListItem key={index}>
                                <ListItemIcon>
                                    { isCorrect(question.correct_answer, state[index]) ? <img alt="correct" src={notepad_check}/>: <img alt="incorrect" src={notepad_x}/> }
                                </ListItemIcon>
                                <ListItemText secondary={he.decode(question.question)} secondaryTypographyProps={{ color: 'textPrimary'}} primary={<Typography color="textSecondary" variant="overline">{`${question.category.split(': ')[1] || question.category}`}</Typography>} />
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListItemText primary={`Correct answer: ${question.correct_answer}`} secondary={`Your answer: ${state[index]}`} />
                            </ListItem>
                            </Paper>
                            </Box>
                        )
                    })
                }
                </List>
            </Box>
            <MainButton onClick={playAgain}>Play again</MainButton>
        </Layout>
    )
}

export default ResultScreen
