import React from 'react'
import styled from 'styled-components'
import { colorsArray }  from '../../colors'

const StepperWrapper = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    background-color: ${props => props.backgroundColor};
    grid-gap: 4px;
`
const Step = styled.div`
    width: 100%;
    background-color: ${props => props.color};
    height: 4px;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Stepper = ({ steps = 10, stepNumber }) => {
    
    const getColor = (num) => {
        if (num < stepNumber) {
            return colorsArray[num]
        }
        return 'transparent'
    }

    return (
        <StepperWrapper backgroundColor='#0F1C43' data-testid='1'>
            {
                [...Array(steps).keys()].map((num) => {
                    return (<Step key={num} color={getColor(num)}/>)
                })
            }
        </StepperWrapper>
    )
}

export default Stepper
