import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from '@material-ui/core'
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import HomeScreen from './screens/HomeScreen'
import QuestionScreen from './screens/QuestionScreen';
import ResultScreen from './screens/ResultScreen';

const TRIVIA_URL = 'https://opentdb.com/api.php?amount=10&difficulty=hard&type=boolean'

function App() {

  const [ questions, setQuestions ] = useState()
  const location = useLocation()

  // Guard redirects to '/' if no state exists
  if (location.pathname !== '/' && !questions) return (<Redirect to={'/'} />)

  const beginGame = async () => {
    try {
      const data = await fetchData()
      setQuestions(data.results)
    } catch(e) {
      console.log(e)
    }
  }

  const fetchData = async () => {
    try {
      const res = await fetch(TRIVIA_URL)
      return await res.json()
    } catch(e) {
      console.log(e)
    } 
  }

  return (
    <div>
      <Helmet>
        <title>Trivia app</title>

        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Arimo:wght@600;700&display=swap" rel="stylesheet"></link>
        <link  rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:300,400,500,700&display=swap" />
        <meta name="description" content="A trivia app" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <Container maxWidth="xs">
        <Switch>
          <Route path="/question/:questionIndex">
            <QuestionScreen questions={questions} />
          </Route>
          <Route path="/results">
            <ResultScreen questions={questions} />
          </Route>
          <Route path="/">
            <HomeScreen begin={beginGame} />
          </Route>
        </Switch>
      </Container>
      
    </div>
  );
}

export default App;
